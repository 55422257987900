
  <span class="switch"
  [class.checked]="checked"
  [class.disabled]="disabled"
  [class.switch-large]="size === 'large'"
  [class.switch-medium]="size === 'medium'"
  [class.switch-small]="size === 'small'"
  [style.background-color]=""
  [style.border-color]="getColor('borderColor')"
  >
  <small [style.background]="getColor('switchColor')">
  </small>
  </span>
  