import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class CityService {
    
    constructor(private customCallingApi: Custom_api_calling) { }

    

    getCityList(data, callback) {
        let query_data = this.get_query_data(data);
        this.customCallingApi.http_calling_get('api/tenant/city/get_city_list?' + query_data, (result) => {
            callback(result);
        });
    }


    // getCityList(data, callback) {
    //     let query_data = this.get_query_data(data);
    //     this.customCallingApi.http_calling_get('api/client/city/get_cities?' + query_data, (result) => {
    //         callback(result);
    //     });
    // }

    get_query_data(data) {
        let query_data = "";
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
                query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
        }
        return query_data;
    }

    
}
