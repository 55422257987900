import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';
import { ApiService } from '../services/ApiService/api-service.service';
import { UserService } from '../services/user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MerchantService {
  // merchant_primary_info: any = {};


  constructor(private customCallingApi: Custom_api_calling, private apiService: ApiService, private userService: UserService) { }
  getMerchantTypeList(data, callback) {
    let query_data = this.apiService.get_query_data(data);
    this.customCallingApi.http_calling_get('api/tenant/merchant_type/get_merchant_type?' + query_data, callback);
  }

  getRedemptionPartnerList(data,callback){
    let query_data = this.apiService.get_query_data(data);
    this.customCallingApi.http_calling_get('api/tenant/redemption_partner/get_redemption_partner_list?' + query_data, callback);
  }

  getMerchantTier(data,callback){
    let query_data = this.apiService.get_query_data(data);
    this.customCallingApi.http_calling_get('api/tenant/merchant/getTierList?' + query_data, callback);
  }

  getRuleActivityList(data,callback){
    let query_data = this.apiService.get_query_data(data);
    this.customCallingApi.http_calling_get('api/tenant/rule_activity/getAllRuleActivity?' + query_data, callback);
  }
  MerchantTypeInfo = new BehaviorSubject({});
  castMerchantTypeInfo = this.MerchantTypeInfo.asObservable();

  fetchSelectedMemberType(code_id: any) {
    let memberTypeInfoApi: string = 'api/tenant/merchant_type/get_merchant_type_by_id/' + code_id;
    this.customCallingApi.http_calling_get(memberTypeInfoApi, (data) => {
      this.MerchantTypeInfo.next(data);
    });
  }

  getCountryList(params, callback) {
    this.customCallingApi.http_calling_get('api/tenant/country/get_country_list', (result) => {
      callback(result);
    });
  }
  getMerchantList(data, callback) {
    let query_data = this.apiService.get_query_data(data);
    this.customCallingApi.http_calling_get('api/tenant/merchant/get_merchant?' + query_data, callback);
  }

  // set_data(item, data) {
  //   this.merchant_primary_info[item] = data;
  // }


  // public loadPrimaryInformation(data, callback: Function) {
  //   this.customCallingApi.http_multi_url_get({
  //     'countries': 'api/tenant/country/get_country_list',
  //     'categories': 'api/tenant/category/getAllCategory',
  //     'sub_categories': 'api/tenant/category/get_category_list?type=all'
  //   }, callback)
  // }



  // getPrivateMerchantInfoById(data, callback) {
  //   this.customCallingApi.http_calling_get("api/tenant/merchant/get_merchant?merchant_id=" + data.merchant_id + '&&is_private=' + data.is_private, (result) => callback(result));
  // }

  // get_merchant_branches_list(data, callback) {
  //   this.customCallingApi.http_calling_get("api/tenant/merchant/get_private_merchant_branches?merchant_id=" + data.merchant_id + '&&is_private=' + data.is_private, (result) => callback(result));
  // }


   /* Description: this function is sending get request for merchant Data by ID
       Params: subscription master id
       Return: Data of subscription master which is requested
       Author: Hardik
    */
   fetch_merchant_master_dataById(Id, callback) {
    this.customCallingApi.http_calling_get('api/seamiles/tenant/subscription/get_subscription_masters?subscriptionMasterId=' + Id, (result)=> {
      return callback(result);
    });
  }

}
