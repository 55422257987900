import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class ProductService {

  constructor(private api_adapter: Custom_api_calling) { }



  get_all_product(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/product/get_product_list?" + query_data, (result) => callback(result));

  }
  get_all_products(callback) {
    this.api_adapter.http_calling_get("api/tenant/product/get_products_list", (result) => callback(result));

  }
  create_product(data, callback) {
    this.api_adapter.http_calling_file_post("api/tenant/product/add_product", data.body, (result) => callback(result));
  }

  modify_product(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/product/edit_product", data.body, (result) => callback(result));
  }
  get_product(data, callback) {
    this.api_adapter.http_calling_get("api/tenant/product/get_product_by_id/" + data, (result) => callback(result));
  }
  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
        query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
  }

  //*** Product Type Start *** //

  add_product_type(data, callback) {
    const api = 'api/tenant/product_type/add_product_type';
    this.api_adapter.http_calling_file_post(api, data, callback);
  }

  edit_product_type(data, callback) {
    this.api_adapter.http_calling_json_put('api/tenant/product_type/edit_product_type', data, (result) => {
      callback(result);
    })
  }

  product_type_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get('api/tenant/product_type/product_type_list?' + query_data, (result) => {
      callback(result);
    });
  }

  fetch_product_type_data(data, callback) {
    this.api_adapter.http_calling_get('api/tenant/product_type/get_product_type_id/' + data.product_type_id, callback);
  }

  //*** Product Type End *** //

  //*** Product Sub Type Start *** //

  add_product_sub_type(data, callback) {
    const api = 'api/tenant/product_sub_type/add_product_sub_type';
    this.api_adapter.http_calling_file_post(api, data, callback);
  }

  edit_product_sub_type(data, callback) {
    this.api_adapter.http_calling_json_put('api/tenant/product_sub_type/edit_product_sub_type', data, (result) => {
      callback(result);
    })
  }

  product_sub_type_list(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get('api/tenant/product_sub_type/product_sub_type_list?' + query_data, (result) => {
      callback(result);
    });
  }

  fetch_product_sub_type_data(data, callback) {
    this.api_adapter.http_calling_get('api/tenant/product_sub_type/get_product_sub_type_id/' + data.product_sub_type_id, callback);
  }

  fetchProductTypeList(callback) {
		this.api_adapter.http_calling_get("api/tenant/product_sub_type/product_type_list", (result) => callback(result));
	}

  //*** Product Sub Type End *** //

}
