import { Injectable } from '@angular/core';
import { Custom_api_calling } from '../adapters/Custom_api_calling';

@Injectable()
export class StatusNameService {

  constructor(private api_adapter: Custom_api_calling) { }



  get_all_status_name(data, callback) {
    let query_data = this.get_query_data(data);
    this.api_adapter.http_calling_get("api/tenant/status/get_all_status?" + query_data, (result) => callback(result));

  }
  create_status_name(data, callback) {
    this.api_adapter.http_calling_file_post("api/tenant/status/add_status", data.body, (result) => callback(result));
  }

  modify_status_name(data, callback) {
    this.api_adapter.http_calling_file_put("api/tenant/status/edit_status", data.body, (result) => callback(result));
  }
  get_status_name(data, callback) {
    this.api_adapter.http_calling_get("api/tenant/status/get_status_by_id/" + data, (result) => callback(result));
  }
  get_query_data(data) {
    let query_data = "";
    let keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== "" && data[keys[i]] != null && data[keys[i]] != undefined)
        query_data += (query_data === "" ? keys[i] + "=" + data[keys[i]] : "&" + keys[i] + "=" + data[keys[i]]);
    }
    return query_data;
  }

 
}
